import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import NavbarBreadcrumbs from './NavbarBreadcrumbs';
import ToggleColorMode from './ToggleColorMode';
import MenuButton from './MenuButton';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { styled } from '@mui/material/styles';
import { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { listItemIconClasses } from '@mui/material/ListItemIcon';

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

function Header({ mode, toggleColorMode }) {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    handleMenuClose(); // Close the menu
    navigate('/');
  };

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: 'none', md: 'flex' },
        width: '100%',
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        maxWidth: { sm: '100%', md: '1500px' },
      }}
      spacing={2}
    >
      <NavbarBreadcrumbs />
      <Stack direction="row" sx={{ gap: 1 }}>
        <MenuButton aria-label="Go to home" onClick={handleHomeClick}>
          <HomeRoundedIcon />
        </MenuButton>
        <MenuButton aria-label="Open menu" onClick={handleMenuOpen}>
          <LogoutRoundedIcon />
        </MenuButton>
        <ToggleColorMode
          mode={mode}
          toggleColorMode={toggleColorMode}
          data-screenshot="toggle-mode"
        />
      </Stack>
      <Menu
        anchorEl={menuAnchorEl}
        id="menu"
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        <MenuItem
          onClick={handleLogout}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: 'auto',
              minWidth: 30,
            },
            [`& .MuiListItemText-root`]: {
              marginRight: 2, // Add margin to the right of the text
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
}

Header.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default Header;
