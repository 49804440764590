import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Box, Container, CircularProgress } from '@mui/material';

function SuccessPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/account');
        }, 5000); // Redirects after 2000 milliseconds

        return () => clearTimeout(timer); // Cleanup the timer
    }, [navigate]);

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}
            >
                <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
                <Typography variant="h4" sx={{ mt: 2 }}>
                    Upload Successful!
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Redirecting to your account...
                </Typography>
                <CircularProgress sx={{ mt: 2 }} />
            </Box>
        </Container>
    );
}

export default SuccessPage;
