import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import axios from 'axios';
import Stack from '@mui/material/Stack';

const userTestimonials = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'WhatsApp',
    description: (
      <a
        href={`https://wa.me/447893943848?text=${encodeURIComponent('Hello, I would like to inquire about your services.')}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Link variant="body2" sx={{ color: 'text.secondary' }}>
          +44 7893 943848
        </Link>
      </a>
    ),
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Email',
    description: (
      <a
        href={`mailto:info@nora-ai.com?body=${encodeURIComponent('Hello, I would like to inquire about your services.')}`}
      >
        <Link variant="body2" sx={{ color: 'text.secondary' }}>
          info@nora-ai.com
        </Link>
      </a>
    ),
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Address',
    description: '483 Green Lanes, N13 4BS, London, United Kingdom',
  },
];

export default function ContactTwo() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#contacttwo') {
      const sectionElement = document.getElementById('contacttwo');
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    organization: '',
    country: '',
    language: '',
    specialty: '',
    ehr: '',
    query: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://440viubsq5.execute-api.af-south-1.amazonaws.com/dev/enterprisecontact', {
        Fullname: formData.name,
        Email: formData.email,
        PhoneNumber: formData.phone,
        OrganizationName: formData.organization,
        Country: formData.country,
        PreferredLanguage: formData.language,
        CurrentEHR: formData.ehr,
        Specialty: formData.specialty,
        Message: formData.query
      });
  
      // Logging response for debugging
      console.log('API response:', response);
  
      // Check if the response status is in the 2xx range
      if (response.status >= 200 && response.status < 300) {
        alert("Your enquiry has been submitted successfully");
      } else {
        alert("Failed to submit enquiry. Please try again.");
      }
    } catch (error) {
      // Log the error for debugging
      console.error('Error submitting form:', error);
  
      // Handle network errors or unexpected issues
      alert("Failed to submit enquiry. Please try again.");
    }
  };
  

  return (
    <>
      <Container
        id="contacttwo"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'column' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3rem, 10vw, 4rem)',
              mt: {xs: 1, md: 2},
              color: (theme) =>
                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
            }}
          >
            EHR integration
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Never write or type another note manually into your EHR system again!<br /> <br />
            Our API enables you to integrate Nora directly into your EHR system. This allows you to access all of Nora's features without leaving your EHR system.
            If you would like Nora to be integrated into your EHR system, please fill out the form below.
          </Typography>
        </Stack>
        <Card sx={{ margin: 'auto', mt: { xs: 2, sm: 1, md: 1 }, p: {xs: 2, md: 3}, pt: 4, maxWidth: '1300px' }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Contact form
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              hiddenLabel
              size="small"
              margin="small"
              required
              fullWidth
              placeholder="Your full name"
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
            />
             <Grid container spacing={2} >
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  hiddenLabel
                  size="small"
                  margin="small"
                  required
                  fullWidth
                  placeholder="Your email address"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ mt: 4 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="number"
                  hiddenLabel
                  size="small"
                  margin="small"
                  fullWidth
                  name="phone"
                  placeholder="Your phone number"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  sx={{ mt: {xs: 2, md: 4 }}}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
            <TextField
              id="organization"
              hiddenLabel
              size="small"
              margin="small"
              fullWidth
              name="organization"
              placeholder="Your organization's name"
              autoComplete="org"
              value={formData.organization}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              id="country"
              hiddenLabel
              size="small"
              margin="small"
              fullWidth
              name="country"
              placeholder="Country"
              autoComplete="country"
              value={formData.country}
              onChange={handleChange}
              sx={{ mt: {xs: 2, md: 4 }}}
            />
            </Grid>
            </Grid>
            <TextField
              id="language"
              hiddenLabel
              size="small"
              margin="small"
              fullWidth
              name="language"
              placeholder="Preferred language"
              autoComplete="language"
              value={formData.language}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
            <TextField
              id="specialty"
              hiddenLabel
              size="small"
              margin="small"
              fullWidth
              name="specialty"
              placeholder="Specialty"
              autoComplete="specialty"
              value={formData.specialty}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
            <TextField
              id="ehr"
              hiddenLabel
              size="small"
              margin="small"
              fullWidth
              name="ehr"
              placeholder="What is the current EHR you use?"
              autoComplete="ehr"
              value={formData.ehr}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
            <TextField
              id="query"
              hiddenLabel
              size="small"
              margin="small"
              required
              fullWidth
              name="query"
              placeholder="If you would like to ask anything specific, please write here."
              multiline
              rows={4}
              value={formData.query}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 4, mb: 2 }}
            >
              Submit
            </Button>
          </form>
        </Card>
        <Grid container spacing={2.5}>
          {userTestimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{testimonial.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {testimonial.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {testimonial.description}
                  </Typography>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
