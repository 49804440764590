import React, { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import html2pdf from 'html2pdf.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useAuth from '../../../useAuth';
import Box from '@mui/material/Box';
import { Card, Typography, Grid } from '@mui/material';
import Title from './Title';
import { CopyAll as CopyIcon, Save as SaveIcon, Download as DownloadIcon } from '@mui/icons-material';

function NoteEditing({ consultationId, notesUri, firstName, surname, date, onSelectItem }) {
    useAuth();
    const [notesContent, setNotesContent] = useState('');
    const [value, setValue] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    useEffect(() => {
        if (!notesUri) {
            console.error("Notes URI not provided.");
            return;
        }

        const fetchNotes = async () => {
            try {
                const response = await fetch(`https://api.nora-ai.com/api/notes/${encodeURIComponent(notesUri)}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setNotesContent(data);
            } catch (error) {
                console.error("Failed to fetch notes:", error);
            }
        };

        fetchNotes();
    }, [notesUri]);

    const sendFeedback = async () => {
        const doctorId = sessionStorage.getItem('cognitoUsername');
        try {
            const response = await fetch('https://api.nora-ai.com/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    doctorId: doctorId,
                    consultationId: consultationId,
                    stars: value,
                    feedback: feedback
                })
            });

            if (!response.ok) {
                throw new Error('Failed to submit feedback');
            }

            setFeedbackSubmitted(true);
            alert('Thank you for your feedback');
        } catch (error) {
            console.error('Feedback submission error:', error);
            alert('Error submitting feedback');
        }
    };

    const handleChange = content => {
        setNotesContent(content);
    };

    const downloadPdf = () => {
        const element = document.createElement('div');

        element.style.fontFamily = 'Inter, sans-serif';
        element.style.fontSize = '13px';
        element.style.lineHeight = '1.25';
        element.style.color = '#000';

        const notesDiv = document.createElement('div');
        notesDiv.innerHTML = notesContent;
        notesDiv.style.marginTop = '10px';

        element.innerHTML = `
        <h2 style="margin-bottom: 30px;">Consultation Details:</h2>
        <p style="margin-bottom: 5px;">Consultation ID: ${consultationId}</p>
        <p style="margin-bottom: 5px;">Patient: ${firstName} ${surname}</p>
        <p style="margin-bottom: 40px;">Date: ${date}</p>
    `;

        element.appendChild(notesDiv);

        const options = {
            margin: 1,
            filename: `Consultation-${firstName}-${date}-Notes.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().from(element).set(options).save();
    };

    const saveNotes = async () => {
        const doctorId = sessionStorage.getItem('cognitoUsername');
        const finalNotesName = `${doctorId}_${consultationId}-final`;
        try {
            const response = await fetch(`https://api.nora-ai.com/api/notes/finalize`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    fileName: finalNotesName,
                    notesContent: notesContent,
                    doctorId: doctorId,
                    consultationId: consultationId
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            alert("Notes saved successfully!");
        } catch (error) {
            console.error("Failed to save notes:", error);
            alert("Failed to save notes.");
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                <Box sx={{margin: 'auto', mt: {xs: 7, sm: 5, md: 2}, maxWidth: { sm: '100%', md: '1500px' }}}>
                        <Button onClick={() => onSelectItem('Past Consultations')} sx={{ mb: 3 }} variant='contained'>
                            Back
                        </Button>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            If you edit your notes, please ensure that you save the changes before leaving the page.
                        </Typography>
                    </Box>
                    <Card sx={{ margin: 'auto', mt: { xs: 5, sm: 4, md: 3 }, p: {xs: 1, sm: 3, md: 3, lg: 5}, maxWidth: '1500px' }}>
                    <Title>Consultation: {firstName} {surname} on {date}</Title>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '10px', mt: 3}}>
                            <Button
                                startIcon={<CopyIcon />}
                                onClick={() => {
                                    navigator.clipboard.writeText(notesContent);
                                    setCopySuccess('Notes copied to clipboard!');
                                    setTimeout(() => setCopySuccess(''), 2000);
                                }}>
                                Copy
                            </Button>
                            <Button
                                startIcon={<SaveIcon />}
                                onClick={saveNotes}
                            >
                                Save
                            </Button>
                            <Button
                                startIcon={<DownloadIcon />}
                                onClick={downloadPdf}>
                                Download
                            </Button>
                        </Box>

                        {copySuccess && <div style={{ color: 'green', margin: 10 }}>{copySuccess}</div>}
                        <ReactQuill
                            theme="snow"
                            value={notesContent}
                            onChange={handleChange}
                            style={{ width: '100%', marginTop: 10 }}
                        />
                        <div>
                            <Rating
                                style={{marginTop: 30 }}
                                name="hover-feedback"
                                value={value}
                                precision={0.5}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                    if (feedbackSubmitted) {
                                        event.preventDefault();
                                        return;
                                    }
                                }}
                                readOnly={feedbackSubmitted}
                            />
                        </div>
                        {!feedbackSubmitted && value !== null && (
                            <div>
                                <TextField
                                    style={{marginTop: 10 }}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    placeholder="Enter your feedback here..."
                                    value={feedback}
                                    onChange={(e) => setFeedback(e.target.value)}
                                />
                                <Button variant="contained" onClick={sendFeedback} style={{marginTop: 10 }}>
                                    Send Feedback
                                </Button>
                            </div>
                        )}
                        {feedbackSubmitted && <div>Feedback received, thank you!</div>}
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default NoteEditing;
