import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import axios from 'axios';

const userTestimonials = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'WhatsApp',
    description: (
      <a
        href={`https://wa.me/447893943848?text=${encodeURIComponent('Hello, I would like to inquire about your services.')}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Link variant="body2" sx={{ color: 'text.secondary' }}>
          +44 7893 943848
        </Link>
      </a>
    ),
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Email',
    description: (
      <a
        href={`mailto:info@nora-ai.com?body=${encodeURIComponent('Hello, I would like to inquire about your services.')}`}
      >
        <Link variant="body2" sx={{ color: 'text.secondary' }}>
          info@nora-ai.com
        </Link>
      </a>
    ),
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Address',
    description: '483 Green Lanes, N13 4BS, London, United Kingdom',
  },
];

export default function ContactTwo() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#contacttwo') {
      const sectionElement = document.getElementById('contacttwo');
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    query: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Post the data to the new API endpoint
      const response = await axios.post('https://440viubsq5.execute-api.af-south-1.amazonaws.com/dev/contactform', {
        fullName: formData.name,
        email: formData.email,
        phoneNumber: formData.phone,
        message: formData.query
      });

      if (response.status === 200) {
        alert("Your feedback has been submitted successfully");
      } else {
        throw new Error('Failed to submit message');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert("Failed to submit message. Please try again.");
    }
  };

  return (
    <>
      <Container
        id="contacttwo"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" color="text.primary">
            Contact us
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Let us know how we may assist you, and someone from our team will get back to you shortly. If you are a clinician needing support, please contact us through one of the channels listed below. For all other questions or feedback, please fill out the query form.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {userTestimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{testimonial.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {testimonial.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {testimonial.description}
                  </Typography>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Card sx={{ margin: 'auto', mt: { xs: 2, sm: 1, md: 1 }, p: 3, pt: 4, maxWidth: '1300px' }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Contact form
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              hiddenLabel
              size="small"
              margin="small"
              required
              fullWidth
              placeholder="Your full name"
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              id="email"
              hiddenLabel
              size="small"
              margin="small"
              required
              fullWidth
              placeholder="Your email address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              sx={{ mt: 3 }}
            />
            <TextField
              id="number"
              hiddenLabel
              size="small"
              margin="small"
              fullWidth
              name="phone"
              placeholder="Your phone number"
              autoComplete="tel"
              value={formData.phone}
              onChange={handleChange}
              sx={{ mt: 3 }}
            />
            <TextField
              id="query"
              hiddenLabel
              size="small"
              margin="small"
              required
              fullWidth
              name="query"
              placeholder="Please let us know how we may help you."
              multiline
              rows={4}
              value={formData.query}
              onChange={handleChange}
              sx={{ mt: 3 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}
