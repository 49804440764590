import React, { useState, useEffect, useRef } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//import { refreshTokenIfNeeded } from '../../../authUtils';
import useAuth from '../../../useAuth';
import Title from './Title';
import { Card, CircularProgress } from '@mui/material';

function NewConsults({ onStartConsultation }) {
    useAuth();
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [surname, setSurname] = useState('');
    const [surnameError, setSurnameError] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [dobError, setDobError] = useState(false);
    const [loading, setLoading] = useState(false);
    const datePickerRef = useRef(null);
    const buttonRef = useRef(null);

    const validateFields = () => {
        setFirstNameError(!firstName);
        setSurnameError(!surname);
        setDobError(!dateOfBirth);
        return firstName && surname && dateOfBirth;
    };

    const startConsultation = async () => {
        if (!validateFields()) {
            alert('Please fill in all the patient details.');
            return;
        }

        setLoading(true); // Show the spinner

        const consultationDetails = {
            DoctorID: sessionStorage.getItem('cognitoUsername'),
            PatientFirstName: firstName,
            PatientSurname: surname,
            PatientDateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
        };

        try {
            const response = await fetch('https://36wkpw46o4.execute-api.af-south-1.amazonaws.com/dev/com/start-consultation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(consultationDetails),
            });

            console.log('Response:', response);

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error Text:', errorText);
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const responseData = await response.json();
            console.log('Response Data:', responseData);

            sessionStorage.setItem('ConsultationID', responseData.ConsultationID);
            
            // Pass the patient details to the callback
            onStartConsultation(consultationDetails);
        } catch (error) {
            console.error('Error starting consultation:', error);
            alert(`There was an error starting the consultation: ${error.message}`);
        } finally {
            setLoading(false); // Hide the spinner
        }
    };

    useEffect(() => {
        if (datePickerRef.current && buttonRef.current) {
            buttonRef.current.style.width = `${datePickerRef.current.clientWidth}px`;
        }
    }, [dateOfBirth]);

    return (
      <React.Fragment>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Box sx={{ margin: 'auto', mt: { xs: 7, sm: 4, md: 2 }, maxWidth: { sm: '100%', md: '1500px' } }}>
                <Title>New Consultation</Title>
                <Typography variant="body1" sx={{ mb: 3 }}>
                Please enter the patient's details to start a new consultation.
                </Typography>
                </Box>
              <Card sx={{margin: 'auto', mt: {xs: 4, sm: 4, md: 2}, p: {xs: 2, sm: 3, md: 3, lg: 4}, maxWidth: '1500px'}}>  
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TextField
                        label="Patient First Name"
                        fullWidth
                        error={firstNameError}
                        helperText={firstNameError ? "*" : ""}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <TextField
                        label="Patient Surname"
                        fullWidth
                        error={surnameError}
                        helperText={surnameError ? "*" : ""}
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <DatePicker
                        label="Date of Birth"
                        fullWidth
                        value={dateOfBirth}
                        onChange={(newValue) => setDateOfBirth(newValue)}
                        renderInput={(params) => <TextField {...params} error={dobError} helperText={dobError ? "Date of birth is required" : ""} />}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <Button
                        onClick={startConsultation}
                        ref={buttonRef}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, mb: 2, }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Start Consultation'}
                    </Button>
                </LocalizationProvider>
              </Card>
            </Grid>
        </Grid>      
      </React.Fragment>
    );
}

export default NewConsults;
