import React from 'react';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import LoginIcon from '@mui/icons-material/Login';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import Start from '@mui/icons-material/Start';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';  // Importing the ContentCopy icon
import Download from '@mui/icons-material/Download';
import Save from '@mui/icons-material/Save';

const CustomButton = ({
    children,
    variant = "contained",
    color = "primary",
    onClick,
    disabled = false,
    btnColor = '#FF9900',
    textColor,
    margin = '8px 0px',
    padding = '10px 20px',
    borderRadius = '5px',
    fontSize = '14px',
    fontWeight = 'normal',
    icon = '', // Specify the icon
    showBackIcon = false, // A custom prop to conditionally render the back icon
    className, // Add className prop
    ...props
}) => {
    const buttonStyles = {
        margin,
        padding,
        borderRadius,
        fontSize,
        fontWeight,
        color: textColor,
        backgroundColor: btnColor,
        textTransform: 'none',
    };

    // Function to determine which icon to render based on the `icon` prop
    const renderIcon = () => {
        if (showBackIcon) {
            return <ArrowBack />;
        }
        switch (icon) {
            case 'Login':
                return <LoginIcon />;
            case 'ViewList':
                return <ViewListIcon />;
            case 'Add':
                return <AddIcon />;
            case 'Edit':
                return <EditIcon />;
            case 'KeyboardVoice':
                return <KeyboardVoiceIcon />;
            case 'Start':
                return <Start />;
            case 'Save':
                return <Save />;
            case 'Download':
                return <Download />;
            case 'Copy':  // Assigning a name to trigger the ContentCopy icon
                return <ContentCopyIcon />;
            default:
                return null;
        }
    };

    return (
        <Button
            variant={variant}
            color={color}
            onClick={onClick}
            disabled={disabled}
            style={buttonStyles}
            startIcon={renderIcon()} // Use renderIcon to determine which icon to display
            className={className} // Apply the className prop
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomButton;
