import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function SignupSuccessPage({ open, startTimeout }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (startTimeout) {
      const timer = setTimeout(() => {
        navigate('/'); // Adjust this as necessary for redirection
      }, 10000); // 15 seconds

      return () => clearTimeout(timer);
    }
  }, [startTimeout, navigate]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        component: 'div', // Use 'div' instead of 'form' to disable form submission
      }}
      disableEscapeKeyDown // Prevent closing with the Escape key
      disableBackdropClick // Prevent closing by clicking outside the dialog
    >
      <DialogTitle>Thank you for signing up to Nora!</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>
          We are currently at maximum capacity for this Beta. We are increasing our usage periodically, so keep an eye out for an activation email.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

SignupSuccessPage.propTypes = {
  open: PropTypes.bool.isRequired,
  startTimeout: PropTypes.bool.isRequired, // Prop to control when the timeout starts
};

export default SignupSuccessPage;
