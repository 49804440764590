import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Footer from './components/Footer';
import getLPTheme from '../home/getLPTheme';
import AppAppBar from './components/AppAppBar';
import './termsofuse.css';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function TermsOfUse() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default', mt: { xs: 2, sm: 3, md: 4 } }}>
        <div className="terms-container">
          <div className="terms-text" style={{ fontSize: '14px', textAlign: 'center' }}>
            <h1>GENERAL TERMS OF USE</h1>
            <h2>Getech Education Ltd (Nora)</h2>
            <p>
              These Terms of Use apply to all users of software and services provided by Getech Education Ltd trading as Nora. Nora is a UK registered company, 
              and we provide software designed to record doctor-patient conversations and generate structured clinical notes. By using our products, you agree 
              to comply with these terms.
            </p>

            <h2>Company Details</h2>
            <ul>
              <li>Registered Name: Getech Education Ltd</li>
              <li>Trading Name: Nora</li>
              <li>Registered Company Number: 14245329</li>
              <li>Website: <a href="http://www.nora-ai.com">www.nora-ai.com</a></li>
              <li>Email: <a href="mailto:info@nora-ai.com">info@nora-ai.com</a></li>
            </ul>

            <h2>Who are we?</h2>
            <p>
              We are Nora, a company that offers AI-powered software to record doctors' conversations with their patients and automatically 
              generate structured clinical notes. We also provide a feature for dictating notes outside of consultations. Nora is fully 
              integrated within Healthbridge, an EHR software vendor, which is currently the only way to access our services.
            </p>

            <h2>SECTION 1 - INTRODUCTION</h2>
            <h2>1. Applicability of these Terms</h2>
            <p>
              1.1 These Terms apply to every agreement we make with you and your use of our software.<br />
              1.2 We reserve the right to change these Terms at any time. The latest version will always apply.<br />
              1.3 If a conflict arises between these Terms and an agreement you may have with Healthbridge, the terms of your agreement with Healthbridge 
              will prevail in terms of payment obligations.<br />
              1.4 The general terms apply to all users, while subscription terms only apply to paid users of Nora through Healthbridge.
            </p>

            <h2>SECTION 2 – GENERAL TERMS</h2>
            <h2>2. Account Creation and Responsibility</h2>
            <p>
              2.1 To access Nora, you must sign up through Healthbridge.<br />
              2.2 Only one user is permitted per account; sharing accounts between multiple doctors is not allowed.<br />
              2.3 If you wish to delete your account, you must request deletion through Healthbridge. Deleting your account will not affect payment 
              obligations already incurred.
            </p>

            <h2>3. Use of Nora and Privacy</h2>
            <p>
              3.1 The purpose of Nora is to turn conversations with your patients into structured clinical notes.<br />
              3.2 Nora does not give, render or support giving medical advice. Diagnosing patients and developing treatment plans are activities to 
              be performed by the doctor or healthcare professional.<br />
              3.3 You are not allowed to use Nora during a consultation without the consent of your patient obtained before use. Nora cannot verify 
              whether you have received consent and does not accept any responsibility if consent was not adequately obtained.
            </p>

            <h2>4. Availability and Maintenance</h2>
            <p>
              4.1 We will do our best to keep our Products up and running 24 hours a day, 7 days a week. We are responsible for the availability 
              and maintenance of our Products.<br />
              4.2 During maintenance, our Products can be (partly) unavailable.<br />
              4.3 We have the right to change our Products. This includes changing, removing, or adding certain features or functionalities of 
              our Products.<br />
              4.4 We do not guarantee that our Products are completely free of errors. Please inform us immediately of any errors, bugs, or 
              malfunctioning of our Products. You can reach out to us by sending an email to info@nora-ai.com or via the Contact Support feature 
              integrated into our Products. We will then do our utmost to resolve your problem as soon as possible.
            </p>

            <h2>5. Force Majeure</h2>
            <p>
              5.1 We will not be liable if we are unable to provide our Products to you due to force majeure. This includes, for example, a 
              non-attributable failure of third parties, hacks, and internet failures.<br />
              5.2 If the force majeure period exceeds 2 uninterrupted months, or when it has become clear that the period will last longer 
              than 2 months, both parties may terminate your Nora Subscription in writing. If you terminate the Nora Subscription in accordance 
              with this article, there is no right to compensation. We will send you an invoice for the (unpaid) period in which you used Nora.
            </p>

            <h2>6. Intellectual Property</h2>
            <p>
              6.1 Nora owns all intellectual property related to the software, including but not limited to copyrights, trademarks, design 
              rights, and patents.<br />
              6.2 Users are granted a limited, non-exclusive right to use Nora. You may not transfer or sublicense this right.
            </p>

            <h2>7. Liability and Indemnification</h2>
            <p>
              7.1 We are not liable for any damages resulting from the use of Nora or its inaccessibility. Your use of the software is at your own risk.<br />
              7.2 We are not responsible for ensuring the accuracy of the clinical notes generated by Nora. Users are required to review the clinical notes 
              generated by Nora to verify their accuracy and truthfulness.<br />
              7.3 Users are responsible for ensuring patient consent is obtained and indemnify us against any claims resulting from lack of consent or misuse of the software.<br />
              7.4 We are not liable for loss of data stored in our system.
            </p>

            <h2>8. Third-Party Providers</h2>
            <p>
              8.1 Nora is integrated into Healthbridge, and users of Nora are subject to Healthbridge’s terms regarding billing, subscription, and data handling.<br />
              8.2 We may engage third parties to assist in providing our services.
            </p>

            <h2>9. Miscellaneous</h2>
            <p>
              9.1 The invalidity of any provision in these Terms will not affect the validity of any other provision.<br />
              9.2 You may not assign or transfer any rights and obligations related to your use of Nora without our written consent.
            </p>

            <h2>10. Governing Law</h2>
            <p>
              10.1 These terms are governed by the laws of the United Kingdom.<br />
              10.2 Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales.
            </p>

            <h2>SECTION 3 – SUBSCRIPTION TERMS</h2>
            <h2>11. Subscriptions and cancellation</h2>
            <p>
              11.1 Nora is available exclusively through Healthbridge Clinical, and users will be billed by Healthbridge. All payment terms and billing disputes are managed by Healthbridge.<br />
              11.2 Before the use of Nora, you must enter into an agreement with us (Subscription). You can find more information about our Subscriptions in Healthbridge.<br />
              11.3 Users may sign up for an optional one-month free trial. After the trial period, access to Nora will be revoked unless users opt-in for the premium subscription.<br />
              11.4 Premium subscriptions offer unlimited access to all features of Nora and are charged monthly. The subscription fees will be added to the user's monthly Healthbridge bill.<br />
              11.5 The term of the Subscription (the Subscription Period) will be agreed upon during the application procedure. The Subscription will commence on the agreed Start Date.<br />
              11.6 Only one account per user is permitted. Shared or group access under a single account is not allowed.<br />
              11.7 You can cancel your Subscription with 30 days prior written notice before the end of the Subscription Period.<br />
              11.8 Payment and collection will be managed by Healthbridge.
            </p>

            <h2>12. Exclusion</h2>
            <p>
              12.1 We reserve the right to terminate the Subscription and exclude you from any (further) use of Nora if you:<br />
              a) violate applicable laws and regulations or these Terms;<br />
              b) fail to meet your payment obligations from the Subscription;<br />
              c) use Nora without the consent of your patient; or<br />
              d) use Nora for a purpose for which it is clearly not intended.<br />
              12.2 We are not obliged to refund any amounts already collected or to pay any damages in case of termination based on article 5.1.
            </p>

            <h2>13. Pricing</h2>
            <p>
              13.1 All prices communicated by us exclude any expenses and VAT.<br />
              13.2 Nora may change its prices of the Subscriptions at renewal, upon 30 days prior written notice. No rights can be derived from historic prices.
            </p>

            <p>For any questions or concerns, please contact us at <a href="mailto:info@nora-ai.com">info@nora-ai.com</a>.</p>
          </div>
        </div>
        <Divider />
        <Footer />
      </Box>
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />
    </ThemeProvider>
  );
}
