import React, { useEffect, useRef } from 'react';

const Visualizer = ({ audioStream }) => {
    const canvasRef = useRef(null);
    const animationRef = useRef();
    const audioContextRef = useRef();
    const analyserRef = useRef();
    const dataArrayRef = useRef();

    useEffect(() => {
        if (!audioStream) return;

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(audioStream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        source.connect(analyserRef.current);

        analyserRef.current.fftSize = 2048;
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        const draw = () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const width = canvas.width;
            const height = canvas.height;
            const bufferLength = analyserRef.current.frequencyBinCount;

            animationRef.current = requestAnimationFrame(draw);

            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            ctx.clearRect(0, 0, width, height); // Clear the canvas

            ctx.lineWidth = 2;
            ctx.strokeStyle = 'rgb(255, 0, 0)'; // Red color for the visualizer line

            ctx.beginPath();

            let sliceWidth = width * 1.0 / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                let v = dataArrayRef.current[i] / 128.0;
                let y = v * height / 2;

                if (i === 0) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            ctx.lineTo(canvas.width, canvas.height / 2);
            ctx.stroke();
        };

        draw();

        return () => {
            cancelAnimationFrame(animationRef.current);
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, [audioStream]);

    return <canvas ref={canvasRef} className="visualizer-canvas"></canvas>;
};

export default Visualizer;
