// src/authUtils.js
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from './UserPool';

export const isAuthenticated = () => {
    const token = sessionStorage.getItem('accessToken');
    return !!token;
};

export const getCurrentUser = () => {
    return UserPool.getCurrentUser();
};

export const refreshTokenIfNeeded = () => {
    return new Promise((resolve, reject) => {
        const currentUser = getCurrentUser();
        if (currentUser == null) {
            reject(new Error('No current user'));
            return;
        }

        currentUser.getSession((err, session) => {
            if (err || !session.isValid()) {
                currentUser.refreshSession(session.getRefreshToken(), (err, session) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    const newAccessToken = session.getAccessToken().getJwtToken();
                    sessionStorage.setItem('accessToken', newAccessToken);
                    resolve(newAccessToken);
                });
            } else {
                const validAccessToken = session.getAccessToken().getJwtToken();
                resolve(validAccessToken);
            }
        });
    });
};