import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
  Link,
  Stack,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useLocation } from 'react-router-dom';

import getSignUpTheme from './getSignUpTheme';
import ToggleColorMode from './ToggleColorMode';
import CountriesDropdown from '../../components/CountriesDropdown';
import SignupSuccessPage from './SignupSuccessPage';

const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  gap: theme.spacing(4),
  width: '100%',
  padding: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px, hsla(220, 30%, 5%, 0.05) 0px 0px 0px 1px',
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh', // Ensure it covers the full viewport height
  paddingBottom: theme.spacing(12),
  backgroundImage:
    'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('sm')]: {
    paddingBottom: 0,
  },
  overflowY: 'auto', // Allow vertical scrolling
  ...theme.applyStyles('dark', {
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.3), hsl(220, 30%, 5%))',
  }),
}));

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      {/*<ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Toggle design language"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>
          Material Design 2
        </ToggleButton>
      </ToggleButtonGroup>*/}
    </Box>
  );
}

const SignUpPage = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [practice, setPractice] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [password, setPassword] = useState(''); // Adding password field
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [startTimeout, setStartTimeout] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailFromQuery = params.get('email');
    if (emailFromQuery) {
      setEmail(emailFromQuery);
    }
  }, [location]);

  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignUpTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const userData = {
      FirstName: firstName,
      LastName: surname,
      Email: email,
      Country: selectedCountry,
      Practice: practice,
      Password: password, // Including password in the request body
    };

    axios.post('https://440viubsq5.execute-api.af-south-1.amazonaws.com/dev/signup', userData)
      .then(response => {
        setLoading(false);
        setOpenModal(true);
        setStartTimeout(true);
      })
      .catch(error => {
        setError(error.response?.data?.error || 'An error occurred');
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={showCustomTheme ? SignUpTheme : defaultTheme}>
      <CssBaseline />
      <SignUpContainer direction="column" justifyContent="space-between">
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            position: { xs: 'static', sm: 'fixed' },
            width: '100%',
            p: { xs: 2, sm: 4 },
          }}
        >
          <Button
            startIcon={<ArrowBackRoundedIcon />}
            onClick={() => window.history.back()}
          >
            Back
          </Button>
          <ToggleColorMode
            mode={mode}
            toggleColorMode={toggleColorMode}
          />
        </Stack>

        <Stack
          sx={{
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Card>
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', mb: 2 }}
            >
              Sign up
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <FormControl>
                <FormLabel htmlFor="firstName">First Name</FormLabel>
                <TextField
                  id="firstName"
                  name="firstName"
                  autoComplete="given-name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="surname">Surname</FormLabel>
                <TextField
                  id="surname"
                  name="surname"
                  autoComplete="family-name"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="practice">Practice Name</FormLabel>
                <TextField
                  id="practice"
                  name="practice"
                  value={practice}
                  onChange={(e) => setPractice(e.target.value)}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                />
              </FormControl>

              <FormControl>
                <CountriesDropdown
                  selectedCountry={selectedCountry}
                  handleCountryChange={(e) => setSelectedCountry(e.target.value)}
                />
              </FormControl>

              <FormControlLabel
                control={<Checkbox required />}
                label={
                  <span>
                    I accept the <Link color="text.secondary" href="/terms-of-use">Terms of Use</Link> and <Link color="text.secondary" href="/privacy-policy">Privacy Policy</Link>
                  </span>
                }
                sx={{ alignSelf: 'flex-start' }}
              />
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive updates via email."
                sx={{ alignSelf: 'flex-start' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ fontSize: '16px', padding: '10px 20px', textTransform: 'none' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Sign Up'}
              </Button>

              {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}

              <Link
                onClick={() => window.location.href = '/login'}
                variant="body2"
                sx={{ alignSelf: 'center' }}
              >
                Already have an account? Sign in
              </Link>
            </Box>
          </Card>
        </Stack>
      </SignUpContainer>
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />

      {/* Modal for Signup Success */}
      <SignupSuccessPage open={openModal} startTimeout={startTimeout} />
    </ThemeProvider>
  );
};

export default SignUpPage;
