import React, { useState } from 'react';
import { Grid, Card, Typography, Box, TextField, Button, Link } from '@mui/material';
import { SettingsSuggestRounded, ConstructionRounded, ThumbUpAltRounded } from '@mui/icons-material';
import axios from 'axios';
import Title from './Title';

const userTestimonials = [
  {
    icon: <SettingsSuggestRounded />,
    title: 'WhatsApp',
    description: (
      <Link href={`https://wa.me/447893943848?text=${encodeURIComponent('Hello, I would like to inquire about your services.')}`} target="_blank" rel="noopener noreferrer" variant="body2" sx={{ color: 'text.secondary' }}>
        +44 7893 943848
      </Link>
    ),
  },
  {
    icon: <ConstructionRounded />,
    title: 'Email',
    description: (
      <Link href={`mailto:info@nora-ai.com?body=${encodeURIComponent('Hello, I would like to inquire about your services.')}`} variant="body2" sx={{ color: 'text.secondary' }}>
        info@nora-ai.com
      </Link>
    ),
  },
  {
    icon: <ThumbUpAltRounded />,
    title: 'Address',
    description: '483 Green Lanes, N13 4BS, London, United Kingdom',
  },
];

export default function Testimonials({ onSelectItem }) { // Receive onSelectItem as a prop
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', query: '' });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        'https://36wkpw46o4.execute-api.af-south-1.amazonaws.com/dev/com/feedback-form',
        {
          firstName: formData.name.split(' ')[0],
          lastName: formData.name.split(' ').slice(1).join(' ') || '',
          email: formData.email,
          phoneNumber: formData.phone,
          message: formData.query,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status >= 200 && response.status < 300) {
        alert('Your feedback has been submitted successfully');
      } else {
        console.error('Unexpected response:', response);
        alert('Failed to submit feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error.response || error.message || error);
      alert('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{ margin: 'auto', mt: { xs: 6, sm: 4, md: 2 }, maxWidth: { sm: '100%', md: '1500px' } }}>
            <Button onClick={() => onSelectItem('Home')} sx={{ mb: 3 }} variant='contained'>
              Back
            </Button>
            <Title>Feedback</Title>
            <Typography variant="body1" sx={{ mb: 3 }}>
              If you are a clinician needing support, please contact us through one of the communication channels listed below. For all other questions or feedback, please fill out the form.
            </Typography>
          </Box>
          <Box sx={{ margin: 'auto', mt: { xs: 4, sm: 4, md: 2 }, maxWidth: { sm: '100%', md: '1500px' } }}>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              {userTestimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ p: 3, height: '100%' }}>
                    <Box sx={{ opacity: '50%' }}>{testimonial.icon}</Box>
                    <div>
                      <Typography fontWeight="medium" gutterBottom>
                        {testimonial.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {testimonial.description}
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Card sx={{ margin: 'auto', mt: { xs: 5, sm: 5, md: 5 }, p: { xs: 2, sm: 3, md: 3, lg: 5 }, maxWidth: '1500px' }}>
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Your full name"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                sx={{ mt: 1, mb: 1 }}
              />
              <TextField
                name="email"
                label="Your email address"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                sx={{ mt: 1, mb: 1 }}
              />
              <TextField
                name="phone"
                label="Your phone number"
                fullWidth
                value={formData.phone}
                onChange={handleChange}
                sx={{ mt: 1, mb: 1 }}
              />
              <TextField
                name="query"
                label="Please leave your feedback or question here."
                fullWidth
                multiline
                value={formData.query}
                onChange={handleChange}
                sx={{ mt: 1, mb: 1 }}
              />
              <Button
                icon="Add"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 1 }}
              >
                Submit
              </Button>
            </form>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
