import React from 'react';
import { useNavigate } from 'react-router-dom';
import ConsultationsList from './ConsultsList';
import useAuth from '../../../useAuth';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Title from './Title';
import { Box } from '@mui/material';

function PastConsultations({ onSelectItem }) {
    useAuth();  // Redirects to login if not authenticated
    const navigate = useNavigate();


    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                <Box sx={{margin: 'auto', mt: {xs: 7, sm: 5, md: 2}, maxWidth: { sm: '100%', md: '1500px' }}}>
                    <Button onClick={() => onSelectItem('Home')} sx={{ mb: 3 }} variant='contained'>
                        Back
                    </Button>
                    <Title>Past Consultations</Title>
                    <ConsultationsList onSelectItem={onSelectItem}/> {/* Pass onSelectItem */}
                </Box>
                </Grid>
            </Grid>      
        </React.Fragment>
    );
}

export default PastConsultations;
