import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
//import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import MenuButton from './MenuButton';
import ToggleColorMode from './ToggleColorMode';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';

function SideMenuMobile({ open, toggleDrawer, mode, toggleColorMode, onSelectItem }) {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    navigate('/'); // Navigate to the home page
    toggleDrawer(false)(); // Close the drawer
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: 'center', flexGrow: 1, p: 1 }}
          >
            <Avatar
              sizes="small"
              alt="Riley Carter"
              src="/static/images/avatar/7.jpg"
              sx={{ width: 24, height: 24 }}
            />
            <Typography component="p" variant="h6">
              Riley Carter
            </Typography>
          </Stack>
          <MenuButton aria-label="Go to home" onClick={handleHomeClick}>
          <HomeRoundedIcon />
        </MenuButton>
          {/*<MenuButton showBadge>
            <NotificationsRoundedIcon />
          </MenuButton>*/}
          <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent onSelectItem={onSelectItem} /> {/* Pass the handler */}
          <Divider />
        </Stack>
        <CardAlert />
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<LogoutRoundedIcon />}
            onClick={handleLogout} // Call handleLogout on click
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

SideMenuMobile.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  open: PropTypes.bool,
  toggleColorMode: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

export default SideMenuMobile;
