import React, { useState, useEffect } from 'react';
import { Grid, Card, Typography, Box, MenuItem, Select, Button } from '@mui/material';
import Title from './Title';

function Settings({ onSelectItem }) { // Receive onSelectItem as a prop
  const [noteType, setNoteType] = useState('SDT'); // Set initial default to 'SDT'

  useEffect(() => {
    // Load the saved note type from local storage on component mount
    const savedNoteType = localStorage.getItem('defaultNoteType');
    if (savedNoteType) {
      setNoteType(savedNoteType);
    }
  }, []);

  const handleNoteChange = (event) => {
    setNoteType(event.target.value);
  };

  const handleSave = () => {
    // Save the selected note type to local storage
    localStorage.setItem('defaultNoteType', noteType);
    alert('Default note type saved!');
  };

  const renderNoteDetails = () => {
    if (noteType === 'SOAP') {
      return (
        <>
          <Box>
            <Typography variant="body2" sx={{ mt: 3 }}>
              SOAP Note:
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ mt: 2 }}>
            (1) Subjective <br /> <br />
            (2) Objective <br /> <br />
            (3) Assessment<br /> <br />
            (4) Plan
          </Typography>
        </>
      );
    } else if (noteType === 'SDT') {
      return (
        <>
          <Box>
            <Typography variant="body2" sx={{ mt: 3 }}>
              SDT Note:
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ mt: 2 }}>
            (1) Symptoms <br /> <br />
            (2) Diagnosis <br /> <br />
            (3) Treatment
          </Typography>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{margin: 'auto', mt: {xs: 7, sm: 5, md: 2}, maxWidth: { sm: '100%', md: '1500px' }}}>
            <Button onClick={() => onSelectItem('Home')} sx={{ mb: 3 }} variant='contained'>
              Back
            </Button>
            <Title>Profile</Title>
          </Box>
          <Card sx={{ margin: 'auto', mt: { xs: 3, sm: 3, md: 3 }, p: {xs: 2, sm: 3, md: 3, lg: 5}, maxWidth: '1500px' }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1, fontSize: 'small' }}>
              Name
            </Typography>
            <Card>Johnny Drama</Card>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1, mt: 3, fontSize: 'small' }}>
              Email Address
            </Typography>
            <Card>info@nora-ai.com</Card>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1, mt: 3, fontSize: 'small' }}>
              Account ID
            </Typography>
            <Card>b6721ca348</Card>
          </Card>
          <Box sx={{margin: 'auto', mt: {xs: 5, sm: 5, md: 6}, maxWidth: { sm: '100%', md: '1500px' }}}>
            <Title>Settings</Title>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Choose your preferred note format
            </Typography>
          </Box>
          <Card sx={{ margin: 'auto', mt: { xs: 3, sm: 3, md: 3 }, p: {xs: 2, sm: 3, md: 3, lg: 5}, maxWidth: '1500px' }}>
            <Select
              value={noteType}
              onChange={handleNoteChange}
              displayEmpty
              fullWidth
              sx={{ maxHeight: 56, width: 215 }}
              inputProps={{ 'aria-label': 'Select note type' }}
            >
              <MenuItem value="" disabled>
                Select Note Type
              </MenuItem>
              <MenuItem value="SOAP">SOAP notes</MenuItem>
              <MenuItem value="SDT">SDT notes</MenuItem>
            </Select>
            {renderNoteDetails()}
            <Button 
              variant="contained" 
              color="primary" 
              sx={{ mt: 3 }} 
              onClick={handleSave}
              disabled={!noteType}
            >
              Save
            </Button>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Settings;
