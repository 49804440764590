import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import healthbridgeLogo from '../../../images/healthbridgelogo.png';
import AWSActivateLogo from '../../../images/AWSActivateLogo.png';
import MSStartupsLogoDark from '../../../images/MSStartupsLogoDarktwo.png'
import edinburghInnovationsLogoLong from '../../../images/edinburghInnovationsLogoLong.svg';
import edinburghInnovationsLogoWhite from '../../../images/edinburghInnovationsLogoWhite.png';
import AWSActivateLogoWhite from '../../../images/AWSActivateLogoWhite.png';
import MSStartupsLogoWhite from '../../../images/MSStartupsLogoWhite.svg';
import healthbridgeLogoWhitepng from '../../../images/healthbridgelogowhite.png';

const whiteLogos = [
  healthbridgeLogoWhitepng,
  MSStartupsLogoWhite,
  AWSActivateLogoWhite,
  edinburghInnovationsLogoWhite
];

const darkLogos = [
  healthbridgeLogo,
  MSStartupsLogoDark,
  AWSActivateLogo,
  edinburghInnovationsLogoLong
];

const logoStyle = {
  width: 'auto',
  height: '50px',
  margin: '20px 20px',
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Trusted by the best companies
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 3, opacity: 0.7 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
