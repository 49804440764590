// src/useAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from './authUtils';

const useAuth = (redirectUrl = '/login') => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate(redirectUrl);
        }
    }, [navigate, redirectUrl]);
};

export default useAuth;
