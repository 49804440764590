// Necessary Imports
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import Pages
import SignUpPage from './pages/signup/signup';
import LoginPage from './pages/login/login';
import AccountPage from './pages/account/AccountPage';
import Home from './pages/home/home'; // Import the new Home component
import SuccessPage from './pages/recording/success';
import SignupSuccessPage from './pages/signup/SignupSuccessPage';
import TermsOfUse from './pages/termsofuse/termsofuse';
import PrivacyPolicy from './pages/privacypolicy/privacypolicy';
import Enterprise from './pages/enterprise/Enterprise';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/" element={<Home />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/SignupSuccessPage" element={<SignupSuccessPage />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/enterprise" element={<Enterprise />} />
            </Routes>
        </Router>
    );
}

export default App;
