import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Height } from '@mui/icons-material';

const ConsultationsList = ({ onSelectItem }) => {
    const [consultations, setConsultations] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchConsultations = async () => {
        try {
            setLoading(true);

            const response = await fetch('https://36wkpw46o4.execute-api.af-south-1.amazonaws.com/dev/consults', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${sessionStorage.getItem('accessToken')}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            const sortedData = data.sort((a, b) => {
                const dateA = new Date(`${a.Date}T${a.Time}Z`);
                const dateB = new Date(`${b.Date}T${b.Time}Z`);
                return dateB - dateA;
            });

            const formattedData = sortedData.map((item, index) => {
                const utcDateTime = new Date(`${item.Date}T${item.Time}Z`);
                const localDateTime = utcDateTime.toLocaleString(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                });

                const [localDate, localTime] = localDateTime.split(', ');

                let status = 'Processing';
                if (item.notes) {
                    status = 'Completed';
                }
                if (item.ErrorReason) {
                    status = 'ERROR';
                }

                return {
                    ...item,
                    id: index + 1,
                    Date: localDate,
                    Time: localTime,
                    Status: status,
                };
            });

            setConsultations(formattedData);
        } catch (error) {
            console.error('Failed to fetch consultations: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchConsultations();
        const intervalId = setInterval(fetchConsultations, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 40 },
        { field: 'PatientFirstName', headerName: 'First Name', width: 150 },
        { field: 'PatientSurname', headerName: 'Surname', width: 150 },
        { field: 'Date', headerName: 'Date', width: 150 },
        { field: 'Time', headerName: 'Time', width: 150 },
        { field: 'PatientDateOfBirth', headerName: 'DOB', width: 150 },
        {
            field: 'Status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                let chipColor = 'default';
                if (params.value === 'Completed') {
                    chipColor = 'success';
                } else if (params.value === 'Processing') {
                    chipColor = 'default';
                } else if (params.value === 'ERROR') {
                    chipColor = 'error';
                }

                return (
                    <Chip label={params.value} color={chipColor} />
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    margin='0px 0px'
                    onClick={() => onSelectItem('NoteEditor', {
                        consultationId: params.row.ConsultationID,
                        notesUri: params.row.final || params.row.notes,
                        firstName: params.row.PatientFirstName,
                        surname: params.row.PatientSurname,
                        date: params.row.Date
                    })}
                >
                    Edit
                </Button>
            ),
        },
        { field: 'ConsultationID', headerName: 'ConsultationID', width: 200 },
    ];

    return (
        <DataGrid
            rows={consultations}
            columns={columns}
            pageSize={5}
            loading={loading}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
        />
    );
};

export default ConsultationsList;
