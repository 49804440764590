import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/Button';
import Visualizer from '../../../components/Visualizer';
//import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { PersonOutlineRounded, CalendarTodayRounded, ScheduleRounded } from '@mui/icons-material';
import Title from './Title';
import { refreshTokenIfNeeded } from '../../../authUtils';
import useAuth from '../../../useAuth';
import MicrophoneSelect from './MicrophoneSelect';

const RecordingPage = ({ patientDetails }) => {
    useAuth(); // Redirects to login if not authenticated
    const navigate = useNavigate();
    const [recording, setRecording] = useState(false);
    const [recordingStopped, setRecordingStopped] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [audioBlob, setAudioBlob] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef();
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [consultationId, setConsultationId] = useState('');
    const [loading, setLoading] = useState(false);
    const maxDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
    const timerRef = useRef(null);
    const timerIntervalRef = useRef(null);
    const [timer, setTimer] = useState(0);
    const [audioStream, setAudioStream] = useState(null);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [selectedMicrophoneId, setSelectedMicrophoneId] = useState('');

    useEffect(() => {
        const consultationID = sessionStorage.getItem('ConsultationID');
        if (!consultationID) {
            alert('No ConsultationID found. Please start a new consultation.');
            navigate('/account');
        } else {
            setConsultationId(consultationID);
        }
    }, [navigate]);

    const handleCancelAndBack = async () => {
        const doctorId = sessionStorage.getItem('cognitoUsername');
        const consultationId = sessionStorage.getItem('ConsultationID');

        try {
            const token = await refreshTokenIfNeeded();
            const response = await fetch('https://cbraqian85.execute-api.af-south-1.amazonaws.com/dev/delete-consultation', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    DoctorID: doctorId,
                    ConsultationID: consultationId
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const responseData = await response.json();
            console.log(responseData.message);
        } catch (error) {
            console.error('Error deleting consultation:', error);
            alert(`There was an error deleting the consultation: ${error.message}`);
        } finally {
            sessionStorage.removeItem('ConsultationID');
            navigate('/account');
        }
    };

    const startRecording = async () => {
        if (recording) {
            stopRecording();
        } else {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedMicrophoneId } });
                mediaRecorderRef.current = new MediaRecorder(stream);
                audioChunksRef.current = [];
                mediaRecorderRef.current.ondataavailable = event => {
                    audioChunksRef.current.push(event.data);
                };
                mediaRecorderRef.current.onstop = async () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    setAudioBlob(audioBlob);
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);
                };
                mediaRecorderRef.current.start();
                setRecording(true);
                setRecordingStopped(false);
                setAudioStream(stream);
                setTimer(0);
                timerIntervalRef.current = setInterval(() => {
                    setTimer((prevTimer) => prevTimer + 1);
                }, 1000);
            } catch (err) {
                console.error("Failed to start recording: ", err);
            }
        }
    };

    const stopRecording = () => {
        clearInterval(timerIntervalRef.current);
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
        setRecording(false);
        setRecordingStopped(true);
        if (audioStream) {
            audioStream.getTracks().forEach(track => track.stop());
        }
        setAudioStream(null);
    };

    const formattedTime = () => {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const supportedTypes = [
        'audio/mp3', 'audio/mp4', 'video/mp4', 'audio/mpeg', 'audio/mpga', 'audio/x-m4a', 'audio/wav', 'audio/wave', 'video/mpeg', 'audio/mpg', 'video/mpg', 'video/webm', 'audio/webm'
    ];
    const maxFileSize = 25 * 1024 * 1024; // 25 MB

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!supportedTypes.includes(file.type) || file.size > maxFileSize) {
            alert(`File not supported. Please ensure the file is one of the following types: mp3, mp4, m4a, wav, webm and does not exceed 25 MB.`);
            return;
        }

        const audio = document.createElement('audio');
        audio.src = URL.createObjectURL(file);
        audio.onloadedmetadata = () => {
            if (audio.duration > 30 * 60) {
                alert("The uploaded audio file exceeds the 30 minutes limit.");
                setSelectedFile(null);
                return;
            }
            setSelectedFile(file);
            setAudioUrl('');
            setAudioBlob(null);
        };
    };

    const handleSend = async () => {
        const doctorId = sessionStorage.getItem('cognitoUsername');
        const consultationId = sessionStorage.getItem('ConsultationID');
        const extension = audioBlob ? 'wav' : selectedFile.name.split('.').pop();
        const contentType = audioBlob ? 'audio/wav' : selectedFile.type;
        const fileName = `${doctorId}_${consultationId}.${extension}`;

        setLoading(true);

        try {
            const accessToken = await refreshTokenIfNeeded();
            const response = await fetch('https://cbraqian85.execute-api.af-south-1.amazonaws.com/dev/upload-url', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    fileName: fileName,
                    contentType: contentType
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to get pre-signed URL');
            }

            const { url: preSignedUrl } = await response.json();

            const uploadResponse = await fetch(preSignedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': contentType,
                },
                body: audioBlob ? audioBlob : selectedFile,
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload file to S3');
            }

            console.log('Audio uploaded successfully');
            alert('Audio uploaded successfully.');
            navigate('/success');
        } catch (error) {
            console.error("Error uploading audio: ", error);
            alert('Error uploading audio: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async () => {
        handleSend();
    };

    const consultationDetails = [
        {
            icon: <PersonOutlineRounded />,
            title: 'Patient',
            description: (
                <div>{patientDetails?.PatientFirstName} {patientDetails?.PatientSurname}</div>
            ),
        },
        {
            icon: <CalendarTodayRounded />,
            title: 'Date of Birth',
            description: (
                <div>{patientDetails?.PatientDateOfBirth}</div>
            ),
        },
        {
            icon: <ScheduleRounded />,
            title: 'Date',
            description: (
                <div>{new Date().toLocaleDateString()}</div>
            ),
        },
    ];

    const handleMicrophoneChange = (deviceId) => {
        setSelectedMicrophoneId(deviceId);
    };

    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                <Box sx={{margin: 'auto', mt: {xs: 7, sm: 5, md: 2}, maxWidth: { sm: '100%', md: '1500px' }}}>
                        <Button onClick={handleCancelAndBack} disabled={loading} sx={{ mb: 3 }} variant='contained'>
                            Cancel and Back
                        </Button>
                        <Title>Recording Consultation</Title>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            Record your conversation or upload pre-recorded audio
                        </Typography>
                    </Box>
                    <Box sx={{margin: 'auto', mt: {xs: 6, sm: 4, md: 2}, maxWidth: { sm: '100%', md: '1500px' }}}>
                    <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
                        {consultationDetails.map((consultation, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ p: 3, height: '100%' }}>
                                    <Box sx={{ opacity: '50%' }}>{consultation.icon}</Box>
                                    <div>
                                        <Typography fontWeight="medium" gutterBottom>
                                            {consultation.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {consultation.description}
                                        </Typography>
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    </Box>
                    <Card sx={{ margin: 'auto', mt: { xs: 5, sm: 4, md: 3 }, p: { xs: 1, sm: 3, md: 5 }, pt: { xs: 3 }, pb: { xs: 3 }, maxWidth: '1500px' }}>
                        <MicrophoneSelect onMicrophoneChange={handleMicrophoneChange} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, marginTop: 3 }}>
                            {!recording && audioUrl && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
                                    <audio src={audioUrl} controls />
                                    <div>Recording Duration: {formattedTime()}</div>
                                    <CustomButton onClick={handleSend} style={{ backgroundColor: '#5FB87E' }} disabled={loading}>Upload</CustomButton>
                                    {loading && <div className="spinner" style={{ margin: 'auto' }}></div>}
                                </Box>
                            )}

                            {recording && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                    <h4 className="recording-heading">Recording in Progress... {formattedTime()}</h4>
                                    <Visualizer audioStream={audioStream} />
                                    <CustomButton onClick={startRecording} style={{ backgroundColor: '#E94951' }}>Stop Recording</CustomButton>
                                </Box>
                            )}

                            {!recording && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}> {/* Increased gap here */}
                                    {recordingStopped && (
                                        <CustomButton
                                            onClick={startRecording}
                                            variant="outlined"
                                            style={{ borderColor: '#E94951', color: '#E94951' }}
                                            disabled={loading}
                                        >
                                            Restart Recording
                                        </CustomButton>
                                    )}
                                    {!recordingStopped && (
                                        <CustomButton onClick={startRecording} style={{ backgroundColor: '#E94951' }} disabled={loading}>
                                            Start Recording
                                        </CustomButton>
                                    )}
                                    {!recordingStopped && (
                                        <>
                                            <Typography>or</Typography>
                                            <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="audio/*" />
                                        </>
                                    )}
                                    {selectedFile && (
                                        <CustomButton onClick={handleSend} style={{ backgroundColor: '#5FB87E' }} disabled={loading}>Upload</CustomButton>
                                    )}
                                    {loading && <div className="spinner" style={{ margin: 'auto' }}></div>}
                                </Box>
                            )}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default RecordingPage;
