import React, { useState } from 'react';
import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import getDashboardTheme from './theme/getDashboardTheme';
import ToggleCustomTheme from './internals/components/ToggleCustomTheme';
import Navbar from './components/Navbar';
import Header from './components/Header';
import NewConsultations from './components/NewConsultations';
import PastConsults from './components/PastConsults';
import Feedback from './components/Feedback';
import SideMenu from './components/SideMenu';
import NoteEditor from './components/NoteEditor';
import Recording from './components/Recording';
import Settings from './components/Settings';
import useAuth from '../../useAuth';

export default function Dashboard() {
  useAuth();

  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [selectedItem, setSelectedItem] = useState('Home');
  const [noteEditorProps, setNoteEditorProps] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null); // New state for patient details
  const dashboardTheme = createTheme(getDashboardTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const handleSelectItem = (item, props = null) => {
    setSelectedItem(item);
    if (item === 'NoteEditor') {
      setNoteEditorProps(props);
    }
  };

  const handleStartConsultation = (details) => {
    setPatientDetails(details);
    setSelectedItem('Recording');
  };

  return (
    <ThemeProvider theme={showCustomTheme ? dashboardTheme : defaultTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <SideMenu onSelectItem={handleSelectItem} />
        <Navbar mode={mode} toggleColorMode={toggleColorMode} onSelectItem={handleSelectItem} />
        <Box
          component="main"
          sx={(theme) => ({
            position: { sm: 'relative', md: '' },
            top: { sm: '48px', md: '0' },
            height: { sm: 'calc(100vh - 48px)', md: '100vh' },
            flexGrow: 1,
            pt: 2,
            backgroundColor: alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
            }}
          >
            <Header mode={mode} toggleColorMode={toggleColorMode} />
            {selectedItem === 'Home' && <NewConsultations onStartConsultation={handleStartConsultation} />}
            {selectedItem === 'Past Consultations' && <PastConsults onSelectItem={handleSelectItem} />}
            {selectedItem === 'Feedback' && <Feedback onSelectItem={handleSelectItem} />} {/* Pass onSelectItem */}
            {selectedItem === 'Settings' && <Settings onSelectItem={handleSelectItem} />} {/* Pass onSelectItem */}
            {selectedItem === 'NoteEditor' && <NoteEditor {...noteEditorProps} onSelectItem={handleSelectItem} />}
            {selectedItem === 'Recording' && <Recording patientDetails={patientDetails} />} {/* Pass patient details as props */}
          </Stack>
        </Box>
        <ToggleCustomTheme
          showCustomTheme={showCustomTheme}
          toggleCustomTheme={toggleCustomTheme}
        />
      </Box>
    </ThemeProvider>
  );
}

