import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const MicrophoneSelect = ({ onMicrophoneChange }) => {
  const [microphones, setMicrophones] = useState([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState('');

  useEffect(() => {
    const getMicrophones = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      setMicrophones(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedMicrophone(audioDevices[0].deviceId);
        onMicrophoneChange(audioDevices[0].deviceId);
      }
    };

    getMicrophones();
  }, [onMicrophoneChange]);

  const handleChange = (event) => {
    setSelectedMicrophone(event.target.value);
    onMicrophoneChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="microphone-select-label">Microphone</InputLabel>
      <Select
        labelId="microphone-select-label"
        value={selectedMicrophone}
        label="Microphone"
        onChange={handleChange}
      >
        {microphones.map((mic) => (
          <MenuItem key={mic.deviceId} value={mic.deviceId}>
            {mic.label || `Microphone ${mic.deviceId}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MicrophoneSelect;
